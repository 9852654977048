<template>
	<v-app class="home" v-if="!success">
		<v-card class="homecard">
			<div class="homeheader">
				<router-link to="/"
					><img :src="images.adkhl" class="homeheaderlogo"
				/></router-link>
			</div>
			<v-alert v-if="error" type="error" class="ma-2"
				><div style="display: flex">
					<p style="margin-bottom: 0">
						{{ error }}. You can check the status
						<router-link to="/status">here</router-link>
					</p>
					<v-icon @click="error = null" style="float: right"
						>fas fa-times-circle</v-icon
					>
				</div></v-alert
			>
			<v-card-title
				class="text-h5"
				style="display: flex; justify-content: space-between; gap: 10px"
				><p style="margin-bottom: 0">Payment Request</p>
				<v-btn @click="$router.push('/status')"
					>Check Status</v-btn
				></v-card-title
			>
			<validation-observer ref="observer" v-slot="{ invalid }">
				<form @submit.prevent="submit" class="px-5">
					<validation-provider
						v-slot="{ errors }"
						name="Appointment Ref#"
						:rules="{ required: true }"
					>
						<v-text-field
							v-model="editedItem.ref"
							label="Appointment Ref# "
							:error-messages="errors"
							required
						></v-text-field>
					</validation-provider>
					<validation-provider
						v-slot="{ errors }"
						name="Name"
						:rules="{ required: true }"
					>
						<v-text-field
							v-model="editedItem.name"
							:counter="50"
							:error-messages="errors"
							label="Name"
							required
						></v-text-field>
					</validation-provider>
					<validation-provider
						v-slot="{ errors }"
						name="ID Card/ Passport"
						:rules="{ required: true }"
					>
						<v-text-field
							v-model="editedItem.nid"
							:counter="50"
							:error-messages="errors"
							label="ID Card/ Passport"
							required
						></v-text-field>
					</validation-provider>
					<validation-provider
						v-slot="{ errors }"
						name="Nationality"
						:rules="{ required: true }"
					>
						<v-select
							v-model="nationality"
							:items="nationalities"
							:error-messages="errors"
							label="Nationality"
							data-vv-name="select"
							class="text-dark"
							required
							light
						>
							<template v-slot:item="{ item, attrs, on }">
								<v-list-item v-bind="attrs" v-on="on">
									<v-list-item-title
										:id="attrs['aria-labelledby']"
										v-text="item"
										class="text-dark"
									>
									</v-list-item-title>
								</v-list-item>
							</template>
						</v-select>
					</validation-provider>
					<validation-provider
						v-slot="{ errors }"
						name="Phone Number"
						:rules="{
							required: true,
							digits: 7,
							regex: '^(7|9)\\d{6}$',
						}"
					>
						<v-text-field
							v-model="editedItem.phone"
							:counter="7"
							:error-messages="errors"
							label="Phone Number"
							required
						></v-text-field>
					</validation-provider>
					<validation-provider
						v-slot="{ errors }"
						name="Insurance 1"
						:rules="{ required: true }"
					>
						<v-select
							v-model="editedItem.insA"
							:items="insList1"
							:error-messages="errors"
							label="Insurance 1"
							data-vv-name="select"
							required
						>
							<template v-slot:item="{ item, attrs, on }">
								<v-list-item v-bind="attrs" v-on="on">
									<v-list-item-title
										:id="attrs['aria-labelledby']"
										v-text="item"
										class="text-dark"
									>
									</v-list-item-title>
								</v-list-item>
							</template>
						</v-select>
					</validation-provider>
					<validation-provider
						v-slot="{ errors }"
						name="Insurance 2"
						:rules="{ required: true }"
					>
						<v-select
							v-model="editedItem.insB"
							:items="insList2"
							:error-messages="errors"
							label="Insurance 2"
							data-vv-name="select"
							required
						>
							<template v-slot:item="{ item, attrs, on }">
								<v-list-item v-bind="attrs" v-on="on">
									<v-list-item-title
										:id="attrs['aria-labelledby']"
										v-text="item"
										class="text-dark"
									>
									</v-list-item-title>
								</v-list-item>
							</template>
						</v-select>
					</validation-provider>
					<validation-provider
						v-slot="{ errors }"
						name="Consultation Type"
						:rules="{ required: true }"
					>
						<v-select
							v-model="editedItem.type"
							:items="[
								'Walk in consultation',
								'Online Consultation',
							]"
							:error-messages="errors"
							label="Consultation Type"
							data-vv-name="select"
							required
						>
							<template v-slot:item="{ item, attrs, on }">
								<v-list-item v-bind="attrs" v-on="on">
									<v-list-item-title
										:id="attrs['aria-labelledby']"
										v-text="item"
										class="text-dark"
									>
									</v-list-item-title>
								</v-list-item>
							</template>
						</v-select>
					</validation-provider>
					<validation-provider
						v-if="isAttachable"
						name="Attachments"
						:rules="{ required: false }"
					>
						<v-file-input
							counter
							multiple
							show-size
							truncate-length="15"
							@change="selectAttachments"
						></v-file-input>
					</validation-provider>
					<v-btn
						v-if="noticeable"
						class="mr-4"
						type="submit"
						:disabled="invalid || submiting"
						@click.prevent="openDialog"
					>
						submits
					</v-btn>
					<v-btn
						v-else
						class="mr-4"
						type="submit"
						:disabled="invalid || submiting"
					>
						submit
					</v-btn>
				</form>
			</validation-observer>
			<div class="homeheaderright">
				<p><b>24hr hotline:</b><br />1440 / 331-3553</p>
			</div>
		</v-card>
		<v-dialog v-model="dialog" max-width="800px">
			<v-card>
				<v-card-title class="text-h5">
					<div class="text-center w-full break-words">
						<p class="text-center">Notice</p>
					</div>
				</v-card-title>
				<div class="text-p text-center w-full break-words">
					<div v-if="noticeable == 'MNDF'">
						<p>Please submit your request to MNDF.</p>
						<div class="NClickBox">
							<v-checkbox
								class="text-center"
								v-model="agree"
								:label="`I've already submitted to MNDF.`"
							>
							</v-checkbox>
						</div>
					</div>
					<div v-if="noticeable == 'Police'">
						<p>Please submit your request to Police medical.</p>

						<div class="NClickBox">
							<v-checkbox
								class="text-center"
								v-model="agree"
								:label="`I've already submitted to Police medical.`"
							>
							</v-checkbox>
						</div>
					</div>
				</div>
				<div class="text-center mt-5 pb-5">
					<v-btn
						type="submit"
						:disabled="!agree"
						@click.prevent="submit"
					>
						submit
					</v-btn>
				</div>
			</v-card>
		</v-dialog>
	</v-app>
	<v-app v-else>
		<v-card class="homecard">
			<div class="homeheader">
				<img :src="images.adkhl" class="homeheaderlogo" />
			</div>
			<div class="successbody">
				<v-alert shaped outlined type="success">
					Your request have been sent successfully, You will receive
					payment link shortly. To check the status click
					<router-link to="/status">here</router-link>
				</v-alert>
			</div>
			<div class="homeheaderright">
				<p><b>24hr hotline:</b><br />1440 / 331-3553</p>
			</div>
		</v-card>
	</v-app>
</template>

<script>
	import {
		required,
		digits,
		email,
		max,
		regex,
	} from "vee-validate/dist/rules";
	import {
		extend,
		ValidationObserver,
		ValidationProvider,
		setInteractionMode,
	} from "vee-validate";
	import axios from "axios";
	import { countries } from "../components/cnts";
	import { insB } from "../components/ibsB";
	import Attachable from "../components/attachable";
	import Noticeable from "../components/noticeable";
	import adkhl from "../assets/adkhl.png";
	import staroflife from "../assets/staroflife.png";
	setInteractionMode("eager");

	extend("digits", {
		...digits,
		message: "{_field_} needs to be {length} digits. ({_value_})",
	});

	extend("required", {
		...required,
		message: "{_field_} can not be empty",
	});

	extend("max", {
		...max,
		message: "{_field_} may not be greater than {length} characters",
	});

	extend("regex", {
		...regex,
		message: "{_field_} {_value_} does not match {regex}",
	});

	extend("email", {
		...email,
		message: "Email must be valid",
	});

	export default {
		computed: {
			isAttachable() {
				return (
					Attachable.includes(this.editedItem.insB) ||
					Attachable.includes(this.editedItem.insA)
				);
			},
			noticeable() {
				if (Noticeable.includes(this.editedItem.insB))
					return this.editedItem.insB;
				if (Noticeable.includes(this.editedItem.insA))
					return this.editedItem.insA;
				return false;
			},
		},
		watch: {
			nationality: function () {
				this.editedItem.nationality = this.nationality;
				if (this.nationality == "Maldives") {
					this.insList1 = ["Aasandha", "none"];
					this.editedItem.insA = "Aasandha";
				} else {
					this.insList1 = this.insList2;
					this.editedItem.insA = "none";
				}
			},
		},
		data() {
			return {
				agree: false,
				error: null,
				success: false,
				submiting: false,
				dialog: false,
				images: {
					adkhl,
					staroflife,
				},
				insList1: ["Aasandha", "none"],
				insList2: insB,
				nationalities: countries,
				nationality: "Maldives",
				editedItem: {
					ref: "",
					name: "",
					nid: "",
					nationality: "Maldives",
					phone: "",
					insA: "Aasandha",
					insB: "none",
					status: "New",
					type: "Walk in consultation",
				},
				attachments: [],
			};
		},
		mounted() {
			if (this.$route.query.aref) {
				this.editedItem.ref = this.$route.query.aref;
			}
		},
		methods: {
			openDialog() {
				this.dialog = true;
			},
			async submit() {
				try {
					this.submiting = true;
					this.error = null;
					const attachments = await this.uploadAttachments();
					if (this.error) return;
					const res = await axios.post(
						`${process.env.VUE_APP_API_ROOT}/requests/`,
						{ ...this.editedItem, attachments }
					);
					if (res.data && res.data.statusCode == 200) {
						this.error = null;
						this.success = true;
					} else {
						this.submiting = false;
						this.error =
							"Please make sure all fields are filled in correctly";
					}
				} catch (e) {
					this.submiting = false;
					this.error =
						"Please make sure all fields are filled in correctly";
				}
			},
			async uploadAttachments() {
				try {
					if (!this.attachments.length) return [];
					const formData = new FormData();
					this.attachments.forEach(element => {
						console.log(element);
						formData.append("attachments", element);
					});
					const res = await axios.post(
						`${process.env.VUE_APP_API_ROOT}/requests/attachment/`,
						formData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					);
					return res.data;
				} catch (e) {
					this.submiting = false;
					this.error = "Unable to upload the attachments";
				}
			},
			selectAttachments(files) {
				this.attachments = [...files];
			},
		},
		components: {
			ValidationProvider,
			ValidationObserver,
		},
	};
</script>

<style>
	.text-dark {
		color: #333;
	}
	.homecard {
		max-width: 500px !important;
		width: calc(100% - 100px);
		margin: 10px auto !important;
	}
	.homeheader {
		padding: 5px 20px;
	}
	.homeheaderlogo {
		height: 53px;
	}
	.homeheaderright {
		margin-top: 10px;
		padding: 5px 20px 20px 20px;
		display: flex;
		align-items: center;
	}
	.homeheaderright p {
		white-space: nowrap;
		margin: 0 0 0 10px;
	}
	.successbody {
		padding: 20px;
	}
	.break-words {
		word-break: break-word;
	}
	.NClickBox {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 10px;
	}
</style>
