<template>
    <v-list-item @click.stop="goto" link class="navlink" :class="isExect" @click="action?action():''">
        <v-list-item-icon>
            <v-icon>{{icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{text}}</v-list-item-title>
    </v-list-item>
</template>

<script>
export default {
    props: ['to','icon','text','action'],
    computed:{
        isExect(){
            if(this.$route.path == this.to) return "router-link-active router-link-exact-active"
            else if(this.$route.path.includes(this.to)) return "router-link-active"
            else return null;
        }
    },
    methods:{
        goto(){
            this.$router.push(this.to).catch(e=>e);
            this.$emit('closeDrawer')
        }
    },
    setup() {
        
    },
}
</script>

<style lang="scss">
    a{
        text-decoration: none;
    }
    .v-list-item__title{
        color:#fff;
    }
    .router-link-exact-active{
        background: #ffffff2c;
        .v-list-item__title{
            color: #ffffff;
        }
    }
    .text-white{
        color:#fff;
    }
    .text-gray{
        color:#d6d6d6;
    }
</style>