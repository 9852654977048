<template>
    <div class="d-block" >
        <div class="login-c left mt-15">
            <v-row justify="center" class="login-form">
                <v-col cols="6">
                    <v-alert
                        color="red"
                        type="error"
                        v-if="error"
                    >{{error}}
                    <div @click="error = null" class="alartclose">
                        <v-icon>fas fa-times-circle</v-icon>
                    </div>
                    </v-alert>
                    <v-alert
                        color="green"
                        type="error"
                        v-if="msg"
                    >{{msg}}
                    <div @click="msg = null" class="alartclose">
                        <v-icon>fas fa-times-circle</v-icon>
                    </div>
                    </v-alert>
                </v-col>
            </v-row>
            <v-row justify="center" class="login-form">
                <v-col cols="12" sm="10" md="8" lg="6" >
                    <h1>ADK Payment Request Portal</h1>
                </v-col>
            </v-row>
            <v-row justify="center" class="login-form" v-if="!resetpass">
                <!-- Login Form -->
                <v-col cols="12" sm="10" md="8" lg="6">
                    <div class="d-flex items-center mb-4 align-center">
                        <h2>Login</h2>
                    </div>
                    <v-form ref="form" v-model="valid" lazy-validation @submit.native="submit" >
                        <v-text-field
                            v-model="username"
                            :rules="nameRules"
                            label="E-mail"
                            required
                            class="mb-2"
                            :disabled="loading"
                        ></v-text-field>
                        <v-text-field
                            v-model="password"
                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPass ? 'text' : 'password'"
                            :rules="passwordRules"
                            label="Password"
                            @click:append="showPass = !showPass"
                            required
                            class="mb-2"
                            :disabled="loading"
                        ></v-text-field>
                        <v-btn
                        v-if="!loading"
                        color="blue accent-4"
                        dark
                        elevation="2"
                        :disabled="loading"
                        @click="submit"
                        class="mt-2"
                        >
                            Submit
                        </v-btn>
                        <v-btn
                        v-if="loading"
                        color="blue accent-4"
                        dark
                        elevation="2"
                        class="mt-2"
                        >
                            <v-progress-circular
                            indeterminate
                            color="white"
                            small
                            ></v-progress-circular>
                        </v-btn>
                        <v-btn
                            color=""
                            light
                            elevation="0"
                            :disabled="loading"
                            @click="resetpass = true"
                            class="mt-2 ml-5"
                            >
                                Reset Password
                        </v-btn>
                    </v-form>
                </v-col>
            </v-row>
            <v-row justify="center" class="login-form" v-if="resetpass">
                <!-- Reset Form -->
                <v-col cols="12" sm="10" md="8" lg="6">
                    <div class="d-flex items-center mb-4 align-center">
                        <h2>Password Reset</h2>
                    </div>
                    <v-form ref="resetform" v-model="valid" lazy-validation @submit.native="submitReset" >
                        <v-text-field
                            v-model="username"
                            :rules="nameRules"
                            label="E-mail"
                            required
                            class="mb-2"
                            :disabled="loading"
                        ></v-text-field>
                        <v-btn
                        color="blue accent-4"
                        dark
                        elevation="2"
                        :disabled="loading"
                        @click="submitReset"
                        class="mt-2"
                        >
                            Submit
                        </v-btn>
                        <div v-if="loading">
                            <v-progress-circular
                            indeterminate
                            color="primary"
                            ></v-progress-circular>
                        </div>
                        <v-btn
                            color=""
                            light
                            elevation="0"
                            :disabled="loading"
                            @click="resetpass = false; msg = ''"
                            class="mt-2 ml-5"
                            >
                                Login
                        </v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </div>
        <div class="login-c right logocont">
            <div class="logofloat">
                <div class="logor">
                    <svg class="cloginr bottom-right" viewBox="0 0 220 192" width="220" height="192" fill="none"><defs class="ng-tns-c94-0"><pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" class="ng-tns-c94-0"><rect x="0" y="0" width="4" height="4" fill="currentColor" class="ng-tns-c94-0"></rect></pattern></defs><rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" class="ng-tns-c94-0"></rect></svg>
                    <img :src="adklogo" alt="ADK" class="logo text-center" >
                    <svg class="cloginr logotr" viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" stroke-width="100" class="text-gray-700 opacity-25 ng-tns-c94-0"><circle r="234" cx="196" cy="23" class="ng-tns-c94-0"></circle><circle r="234" cx="790" cy="491" class="ng-tns-c94-0"></circle></g></svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import logo from "../assets/logowhite.png"
    export default {
        data: () => ({
            msg:"",
            resetpass:false,
            loading:false,
            error:null,
            adklogo:logo,
            valid: true,
            showPass: false,
            username: '',
            nameRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            password: '',
            passwordRules: [
                v => !!v || 'Password is required'
            ],
        }),
        methods: {
            submitReset(){
                this.loading = true;
                this.error = "";
                this.msg = "";
                if(this.$refs.resetform.validate()){
                    console.log(this.username)
                    this.$http.post(`${process.env.VUE_APP_API_ROOT}/auth/requestPassword`,{email:this.username})
                        .then(this.handleReset)
                        .catch(this.handleResetErr)
                }
                else{
                    this.error = "Missing fields";
                }
            },
            handleReset(r){
                this.loading = false;
                this.resetpass = false;
                this.msg = `Password reset link sent to ${r.data.data.email}`
            },
            handleResetErr(e){
                this.loading = false;
                this.error = e.response.data.message;
            },
            validate () {
                this.$refs.form.validate()
            },
            reset () {
                this.$refs.form.reset()
            },
            resetValidation () {
                this.$refs.form.resetValidation()
            },
            async submit () {
                if(this.password && this.username){
                    if(this.$refs.form.validate()){
                        this.error = ""
                        this.loading = true;
                        this.$store.dispatch('login', {email:this.username,password:this.password})
                            .then(this.handleLogin)
                            .catch(this.handleErr)
                    }
                    else{
                        this.error = "Missing fields"
                    }
                }else{
                    this.error = "Missing fields"
                }
            },
            handleLogin(r){
                r; this.loading = false
            },
            handleErr(e){
                if(e.response.data){
                    this.loading = false;
                    this.error = e.response.data.message
                }
            }
        },
    }
</script>

<style lang="scss">
    .login-c{
        width: 50%;
    }
    .left{
        float: left;
    }
    .right{
        float:right;
    }
    .alartclose{
        background-color: transparent !important;
        border:transparent !important;
        box-shadow: none !important;
        float: right;
        min-width: unset !important;
        height: unset !important;
        cursor: pointer;
    }
    .logocont{
        position: relative;
        height: 100vh;
        .top-left{
            position: absolute;
            top:0;
            right: 0;
        }
        .bottom-right{
            position: absolute;
            bottom:-55px;
            left: 0;
        }
        .cloginr{
            color: #2962FF  ;
        }
        .logofloat{
            background: #1754fc ;
            position: fixed;
            width:50%;
            height: 100%;
            .logor{
                position: relative;
                width: 100%;
                height: 100%;
                .logo{
                    position: absolute;
                    top: 50%;
                    left:50%;
                    transform: translate(-50%,-50%);
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .login-c.right.logocont{
            display: none;
        }
        .login-c.left{
            width:100%;
            padding:0 30px;
        }
        .row.login-form > .col.col-6{
            flex: 1 1 100%;
            max-width: 100%;
        }
    }
</style>