import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {},
    loading:false,
    showbgstate:true,
    noNet:false,
  },
  mutations: {
    setUser(state,user){
      state.user = user;
    },
    setLoading(state,bool){
      state.loading = bool;
    },
    setToken(state,token){
      state.token = token;
    },
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, token, user){
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
    setNoNet(state){
      state.noNet = true;
    },
    setNet(state){
      state.noNet = false;
    }
  },
  actions: {
    startLoading({commit}){
      commit('setLoading',true);
    },
    endLoading({commit}){
      commit('setLoading',false);
    },
    login({commit}, user){
        return new Promise((resolve, reject) => {
          commit('auth_request')
          axios({url: process.env.VUE_APP_API_ROOT+'/auth/login', data:user, method: 'POST' })
          .then(resp => {
            const token = resp.data.data.token
            const user = resp.data.data.user
            localStorage.setItem('token', token)
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            commit('auth_success', token, user)
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
        })
    },
    logout({commit}){
      return new Promise((resolve) => {
        commit('logout')
        axios.get(process.env.VUE_APP_API_ROOT+'/auth/logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    checkLogin({commit}){
      return new Promise((resolve,reject) => {
        axios.get(process.env.VUE_APP_API_ROOT+'/auth/isLogin')
        .then(resp=>{
          commit('setUser',resp.data);
          resolve(resp.data);
        })
        .catch(e=>{
          if(e.message !== "Network Error"){
            commit('setToken',null);
            localStorage.removeItem('token')
            delete axios.defaults.headers.common['Authorization']
          }
          else{
            commit('setNoNet');
          }
          reject(e)
        })
      })
    }
  },
  modules: {
  },
  getters : {
    // checkLogin: async (state) => {
    //   if(!state.token){
    //     return false;
    //   }else{
    //     try{
    //       const resp = await axios.get(process.env.VUE_APP_API_ROOT+'/auth/isLogin');
    //       state.user = resp.data;
    //       return resp;
    //     }catch(e){
    //       console.log(e)
    //       state.token = null;
    //       localStorage.removeItem('token')
    //       delete axios.defaults.headers.common['Authorization']
    //       return false;
    //     }
    //   }
    // },
    isLogin : state => !!state.token, 
    getUser: state => state.user,
    isLoading: state => state.loading,
    netErr: state=> state.noNet
  }
})
