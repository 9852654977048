<template>
    <v-app>
        <div class="loading">
        <div class="comp text-center">
            <img class="logoimg" :src="loadingimg" alt="">
            <v-progress-circular indeterminate color="blue" class="spinner" ></v-progress-circular>
        </div>
    </div>
    </v-app>
</template>
<script>
import logo from '../assets/logo.png'
export default {
    data() {
        return {
            loadingimg: logo,
        }
    },
}
</script>
<style lang="scss">
    .loading{
        position: fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        widows: 100%;
        height: 100%;
        z-index: 9999;
        background: rgb(255, 255, 255);
        .comp{
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            transform: translateY(-50%);
            top: 50%;
            position: absolute;
            .logoimg{
                width:100px
            }
            .spinner{
                margin-top:50px;
            }
        }
    }
</style>