<template>
	<div>
		<v-app-bar
			color="blue accent-4"
			dense
			elevation="4"
			dark
			fixed
			class="zioi"
		>
			<v-btn icon @click.stop="drawer = !drawer">
				<v-icon>fas fa-bars</v-icon>
			</v-btn>
			<v-toolbar-title class="text-subtitle-1"
				>Payment Request Portal</v-toolbar-title
			>
		</v-app-bar>
		<v-navigation-drawer
			v-if="drawer"
			v-model="drawer"
			fixed
			temporary
			class="navmenu"
			color="blue accent-4"
			dark
		>
			<v-list>
				<v-list-item class="px-2" link @click.stop="toprofile">
					<v-list-item-avatar>
						<img :src="adklogowhite" alt="ADK Logo" />
					</v-list-item-avatar>
					<v-list>
						<v-list-item-title class="text-white">{{
							userinfo.name
						}}</v-list-item-title>
						<v-list-item-title class="text-body-2 mt-1 text-gray">{{
							userinfo.email
						}}</v-list-item-title>
					</v-list>
				</v-list-item>
			</v-list>
			<v-divider></v-divider>
			<v-list>
				<navlink
					icon="fas fa-clipboard-list"
					text="All"
					to="/admin/"
					v-on:closeDrawer="closeDrawer"
				/>
				<navlink
					icon="fas fa-notes-medical"
					text="New Requests"
					to="/admin/new"
					v-on:closeDrawer="closeDrawer"
				/>
				<navlink
					icon="fas fa-credit-card"
					text="Payment Pending"
					to="/admin/pending"
					v-on:closeDrawer="closeDrawer"
				/>
				<navlink
					icon="fas fa-clipboard-check"
					text="Completed"
					to="/admin/completed"
					v-on:closeDrawer="closeDrawer"
				/>
				<navlink
					icon="fas fa-times"
					text="Canceled"
					to="/admin/canceled"
					v-on:closeDrawer="closeDrawer"
				/>
				<v-list-item link class="navlink" @click="openDownload">
					<v-list-item-icon>
						<v-icon>fas fa-clipboard-check</v-icon>
					</v-list-item-icon>
					<v-list-item-title>Download report</v-list-item-title>
				</v-list-item>
			</v-list>
			<v-divider></v-divider>
			<v-list>
				<navlink
					v-if="userinfo.role == 'admin'"
					icon="fas fa-users"
					text="Users"
					to="/admin/users"
					v-on:closeDrawer="closeDrawer"
				/>
				<navlink
					icon="fas fa-sign-out-alt"
					text="Logout"
					:action="logout"
				/>
			</v-list>
		</v-navigation-drawer>
		<v-dialog v-model="downloadForm" width="auto" persistent>
			<v-card>
				<v-card-title>
					<span class="text-h5">Download statistics report</span>
				</v-card-title>
				<v-card-text>
					Download statistics.
					<v-container>
						<v-row>
							<v-col>
								<v-date-picker
									v-model="downloadStartDate"
									:landscape="false"
									:reactive="true"
								/>
							</v-col>
							<v-col>
								<v-date-picker
									v-model="downloadEndDate"
									:landscape="false"
									:reactive="true"
								/>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue-darken-1"
						variant="text"
						@click="downloadForm = false"
					>
						Close
					</v-btn>
					<v-btn
						:disabled="isDownloading"
						color="blue-darken-1"
						variant="text"
						@click="downloadStats"
					>
						Download
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import navlink from "./navigationdrawer/navlink.vue";
	import logowhite from "../assets/logowhite.png";
	export default {
		computed: {
			userinfo: function () {
				return this.$store.getters.getUser;
			},
		},
		data() {
			return {
				adklogowhite: logowhite,
				drawer: false,
				username: "",
				email: "",
				downloadForm: false,
				downloadStartDate: this.dateFormat(new Date()),
				downloadEndDate: this.dateFormat(new Date()),
				isDownloading: false,
			};
		},
		async mounted() {
			if (!this.userinfo) {
				await this.$store.dispatch("checkLogin");
			}
		},
		methods: {
			toprofile() {
				this.$router.push("/admin/profile").catch(e => e);
				this.drawer = false;
			},
			closeDrawer() {
				this.drawer = false;
			},
			logout() {
				this.$store.dispatch("logout");
			},
			openDownload() {
				this.downloadForm = true;
				this.closeDrawer();
			},
			dateFormat($date) {
				return $date.toISOString().slice(0, 10);
			},
			downloadBlob(content, filename, contentType) {
				// Create a blob
				var blob = new Blob([content], { type: contentType });
				var url = URL.createObjectURL(blob);

				// Create a link to download it
				var pom = document.createElement("a");
				pom.href = url;
				pom.setAttribute("download", filename);
				pom.click();
			},
			downloadStats() {
				this.isDownloading = true;
				const route = `${process.env.VUE_APP_API_ROOT}/reports/download?start=${this.downloadStartDate}&end=${this.downloadEndDate}`;
				this.$http
					.get(route, {})
					.then(r => {
						this.isDownloading = false;
						this.downloadBlob(
							r.data,
							`payment-req-statistics-_-${this.downloadStartDate}-${this.downloadEndDate}.csv`,
							"text/csv;charset=utf-8;"
						);
					})
					.catch(e => {
						this.isDownloading = false;
						console.log(e);
					});
			},
		},
		components: {
			navlink,
		},
	};
</script>

<style lang="scss">
	.zioi {
		z-index: 101 !important;
	}
	.navmenu {
		z-index: 102 !important;
	}
</style>
