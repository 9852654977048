<template>
    <v-app>
        <div class="nowifi text-center">
            <img class="nowifiimg" :src="wifislash" alt="">
            <h3>ADK Payment Request Portal</h3>
            <h4>ADK Hospital</h4>
        </div>
    </v-app>
</template>

<script>
import wslsh from '../assets/wifislash.png'
export default {
    data() {
        return{
            wifislash:wslsh,
        }
    },
}
</script>

<style>
    .nowifi{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
    }
    .nowifiimg{
        width: 200px;
    }
</style>