<template>
	<div v-if="$store.getters.netErr">
		<nonet />
	</div>
	<div v-else>
		<div v-if="$route.meta.isAdmin">
			<loading v-if="$store.getters.isLoading" />
			<v-app v-if="$store.getters.isLogin">
				<div>
					<navigationdrawer />
					<div class="appbody mt-13">
						<div class="loader" v-if="isLoading">
							<v-progress-circular
								indeterminate
								color="blue accent-4"
								class="loadingprogress"
							/>
						</div>
						<router-view v-if="scopeCheck" />
						<forb v-else />
					</div>
				</div>
				<Footer />
			</v-app>
			<v-app v-else>
				<login />
			</v-app>
		</div>
		<div v-else>
			<loading v-if="$store.getters.isLoading" />
			<router-view v-if="scopeCheck" />
			<forb v-else />
		</div>
	</div>
</template>

<script>
	import navigationdrawer from "./components/navigationdrawer.vue";
	import loading from "./components/loading.vue";
	import Footer from "./components/footer.vue";
	import login from "./components/login.vue";
	import forb from "./views/403.vue";
	import nonet from "./views/noNet.vue";
	export default {
		name: "App",
		computed: {
			scopeCheck: function () {
				if (this.$route.meta && this.$route.meta.requiresUserRole) {
					if (this.$store.getters.getUser.role == "admin")
						return true;
					else if (
						this.$route.meta.requiresUserRole ==
						this.$store.getters.getUser.role
					)
						return true;
					return false;
				} else return true;
			},
		},
		data: () => ({
			isLoading: false,
			isLogin: false,
			chkLogininterval: null,
		}),
		mounted() {
			this.chkLogininterval = setInterval(
				this.chkLogin.bind(this),
				10000
			);
		},
		beforeUnmount() {
			if (this.chkLogininterval) clearInterval(this.chkLogininterval);
		},
		methods: {
			async chkLogin() {
				if (this.$store.getters.isLogin)
					await this.$store.dispatch("checkLogin");
			},
		},
		components: {
			navigationdrawer,
			login,
			Footer,
			forb,
			loading,
			nonet,
		},
	};
</script>

<style lang="scss">
	.loader {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: #ffffffe7;
		display: flex;
		z-index: 100;
		.loadingprogress {
			width: 100%;
			margin-top: 100px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.app-login {
		min-height: 100vh;
	}
	img {
		max-width: 100%;
	}
	.appbody {
		position: relative;
		min-height: calc(100vh - 88px);
	}
	.w-full {
		width: 100%;
	}
	.loader-box {
		position: relative;
		height: 200px;
	}
	.loader-box .loader-spin {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
</style>
