import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Request.vue";
import store from "../store/index";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: { requiresAuth: false, isAdmin: false },
	},
	{
		path: "/status",
		name: "Status",
		component: () =>
			import(/* webpackChunkName: "page_status" */ "../views/Status.vue"),
		meta: { requiresAuth: false, isAdmin: false },
	},
	{
		path: "/admin",
		name: "admin",
		component: () =>
			import(
				/* webpackChunkName: "page_allrequests" */ "../views/allrequests.vue"
			),
		meta: { requiresAuth: true, isAdmin: true },
	},
	{
		path: "/admin/new",
		name: "adminNew",
		component: () =>
			import(
				/* webpackChunkName: "page_newRequests" */ "../views/newRequests.vue"
			),
		meta: { requiresAuth: true, isAdmin: true },
	},
	{
		path: "/admin/pending",
		name: "adminPending",
		component: () =>
			import(
				/* webpackChunkName: "page_pendingRequests" */ "../views/pendingRequests.vue"
			),
		meta: { requiresAuth: true, isAdmin: true },
	},
	{
		path: "/admin/completed",
		name: "adminComp",
		component: () =>
			import(
				/* webpackChunkName: "page_completedRequests" */ "../views/completedRequests.vue"
			),
		meta: { requiresAuth: true, isAdmin: true },
	},
	{
		path: "/admin/canceled",
		name: "adminCncl",
		component: () =>
			import(
				/* webpackChunkName: "page_canceledRequests" */ "../views/canceledRequests.vue"
			),
		meta: { requiresAuth: true, isAdmin: true },
	},
	{
		path: "/admin/profile",
		name: "adminProfile",
		component: () =>
			import(
				/* webpackChunkName: "page_profileRequests" */ "../views/profile.vue"
			),
		meta: { requiresAuth: true, isAdmin: true },
	},
	{
		path: "/admin/users",
		name: "adminUsers",
		component: () =>
			import(/* webpackChunkName: "page_users" */ "../views/users.vue"),
		meta: { requiresAuth: true, isAdmin: true, requiresUserRole: "admin" },
	},
	{
		path: "/admin/users/new/",
		name: "adminNewUsers",
		component: () =>
			import(
				/* webpackChunkName: "page_users_new" */ "../views/newUser.vue"
			),
		meta: { requiresAuth: true, isAdmin: true, requiresUserRole: "admin" },
	},
	{
		path: "/admin/users/edit/:id",
		name: "adminEditUsers",
		component: () =>
			import(
				/* webpackChunkName: "page_users_edit" */ "../views/editUser.vue"
			),
		meta: { requiresAuth: true, isAdmin: true, requiresUserRole: "admin" },
	},
	{
		path: "/admin/auth/reset",
		name: "adminResetpass",
		component: () =>
			import(
				/* webpackChunkName: "page_resetpass" */ "../views/resetpass.vue"
			),
		meta: { requiresAuth: false, isAdmin: false },
	},
	{
		path: "/admin/*",
		name: "404Admin",
		component: () =>
			import(/* webpackChunkName: "page_404" */ "../views/404.vue"),
		meta: { requiresAuth: true, isAdmin: true },
	},
	{
		path: "*",
		name: "404",
		component: () =>
			import(/* webpackChunkName: "page_404" */ "../views/404.vue"),
		meta: { requiresAuth: false, isAdmin: false },
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	from;
	store.dispatch("startLoading");
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (store.getters.isLogin) await store.dispatch("checkLogin");
		next();
	} else {
		next();
	}
});
router.afterEach(() => {
	setTimeout(() => {
		store.dispatch("endLoading");
	}, 300);
	window.scroll({
		top: 0,
		left: 0,
		behavior: "smooth",
	});
});
export default router;
